export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/custom_app/dashboard/Dashboard.vue'),
    meta: {
      resource: 'login_user',
      action: 'read',
    },
  },
  {
    path: '/node_query',
    name: 'node_query',
    component: () => import('@/views/custom_app/client/survery_management/SurveyViewer.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/survey_preview',
    name: 'survey_preview',
    component: () => import('@/views/custom_app/client/iframe/SurveyPreview.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/custom_app/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/logout',
    name: 'auth-logout',
    component: () => import('@/views/custom_app/authentication/Logout.vue'),
    meta: {
      resource: 'login_user',
      action: 'read',
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/custom_app/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/custom_app/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register/planselect',
    name: 'auth-register-planselect',
    component: () => import('@/views/custom_app/authentication/PlanSelect.vue'),
    meta: {
      layout: 'full',
      resource: 'clientregister',
      action: 'read',

    },
  },
  {
    path: '/register/creditcardpay',
    name: 'auth-register-creditcardpay',
    component: () => import('@/views/custom_app/authentication/CreditCardPay.vue'),
    meta: {
      layout: 'full',
      resource: 'clientregister',
      action: 'read',

    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/custom_app/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/custom_app/error/Error404.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
]
