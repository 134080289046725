export default {
  state: {
    plan_tags: [
      { title: 'FREE', value: 'free' },
      { title: 'BASIC', value: 'basic' },
      { title: 'STANDART', value: 'standart' },
      { title: 'PRO', value: 'pro' },
      { title: 'ENTERPRISE', value: 'enterprise' },
    ],
  },
  getters: {
    getPlanTags: state => state.plan_tags,
  },
  mutations: {
    // updateOrganizationData(state, payload) {
    //   state.organization_data = payload
    // },
  },
}
