export default {
  // Endpoints
  loginEndpoint: '/jwt/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  tempStorageTokenKeyName: 'tempAccessToken',
  tempStorageRefreshTokenKeyName: 'tempRefreshToken',

  countries: '/countries/',
  currency: '/currency/',
  plan: '/plan/',
  subplan: '/subplan/',
  planlocation: '/planlocation/',
  discount: '/discount/',
  customer: '/customer/',
  feedback: '/feedback/',
  customeruser: '/customeruser/',
  companyreturnurl: '/companyreturnurl/',
  companyprofile: '/companyprofile/',
  companysavedcard: '/companysavedcard/',
  feedbackcomments: '/feedbackcomments/',
  feedbacktags: '/feedbacktags/',
  companytags: '/companytags/',
  nodeuseraccess: '/nodeuseraccess/',
  loginUserEndpoint: '/account/userlogin',
  userinfo: '/account/userinfo',
  loginascompanyuser: '/account/loginascompanyuser',
  registerClientEndpoint: '/account/clientregister/',
  clientregistercreditcartpay: '/account/clientregistercreditcartpay/',
  forgetPasswordSendEmailEndpoint: '/account/forgetpasswordsendemail',
  checkForgetPasswordTokenEndpoint: '/account/checkforgetpasswordtoken',
  setForgetPasswordEndpoint: '/account/setforgetpassword',
  clientregisterfreeplan: '/account/clientregisterfreeplan',
  clientregistercheckcountry: '/account/clientregistercheckcountry',
  getnodeurl: '/getnodeurl/',
  setsurveyanswer: '/setsurveyanswer/',
  organizationtype: '/organizationtype/',
  getorganizationtype: '/getorganizationtype/',
  companyorganization: '/companyorganization/',
  companyorganizationnodes: '/companyorganizationnodes/',
  nodesurveys: '/nodesurveys/',
  subscriptionrenewal: '/subscriptionrenewal/',
  subscriptionupgradeamountcalculation: '/subscriptionupgradeamountcalculation/',
  subscriptionupgrade: '/subscriptionupgrade/',
  surveygroups: '/surveygroups/',
  groupnodes: '/groupnodes/',
  qrcodegroupnodes: '/qrcodegroupnodes/',
  surveypreview: '/surveypreview/',
  nodesurveyanswermedia: '/nodesurveyanswermedia/',
  support: '/support/',
  supportcomments: '/supportcomments/',
  subscription: '/subscription/',
  invoice: '/invoice/',
  dashboardinfo: '/dashboardinfo/',
  nodereadhistory: '/nodereadhistory/',
  dashboardreportdata: '/dashboardreportdata/',
  supportcommentsmedia: '/supportcommentsmedia/',
  paymentcheck: '/payment/paymentcheck/',
  invoicepaywithcreditcard: '/invoicepaywithcreditcard/',
  nodeqrcode: '/nodeqrcode/',
  qrcodegroups: '/qrcodegroups/',

}
