export default {
  state: {
    organization_data: null,
  },
  getters: {
    getOrganizationData: state => {
      return state.organization_data
    },
  },
  mutations: {
    updateOrganizationData(state, payload) {
      state.organization_data = payload
    },
  },
}
