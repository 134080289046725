import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from "axios";

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function getInitialLocale() {
  const accept_languages = [
    'tr',
    'en',
    'fr',
    'de',
    'pt',
    'it',
    'es',
    'ja',
    'ru',
    'ko',
  ]
  const browserLocale = navigator.language.split('-')[0]
  const storedLocale = localStorage.getItem('current_locale')
  const defaultLocale = 'en'

  if (storedLocale) {
    return storedLocale
  } if (accept_languages.find(language => language == browserLocale)) {
    localStorage.setItem('current_locale', browserLocale)
    return browserLocale
  }
  localStorage.setItem('current_locale', defaultLocale)
  return defaultLocale
}

export default new VueI18n({
  locale: getInitialLocale(),
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
})
