export default [
  {
    path: '/support/new_support',
    name: 'new_support',
    component: () => import('@/views/custom_app/support/NewSupport.vue'),
    meta: {
      resource: 'support_management',
      action: 'read',
    },
  },
  {
    path: '/return_url_management',
    name: 'return_url_management',
    component: () => import('@/views/custom_app/client/account_management/AccountManagement.vue'),
    meta: {
      resource: 'return_url_management',
      action: 'read',
    },
  },
  {
    path: '/payment_method',
    name: 'payment_method',
    component: () => import('@/views/custom_app/client/account_management/AccountManagement.vue'),
    meta: {
      resource: 'customer_admin_management',
      action: 'read',
    },
  },
  {
    path: '/password_change',
    name: 'password_change',
    component: () => import('@/views/custom_app/client/account_management/AccountManagement.vue'),
    meta: {
      resource: 'client',
      action: 'read',
    },
  },
  {
    path: '/company_detail',
    name: 'company_detail',
    component: () => import('@/views/custom_app/client/account_management/AccountManagement.vue'),
    meta: {
      resource: 'customer_admin_management',
      action: 'read',
    },
  },
  {
    path: '/invoices_detail',
    name: 'invoices_detail',
    component: () => import('@/views/custom_app/client/account_management/AccountManagement.vue'),
    meta: {
      resource: 'customer_admin_management',
      action: 'read',
    },
  },
  {
    path: '/plan_management',
    name: 'plan_management',
    component: () => import('@/views/custom_app/client/account_management/AccountManagement.vue'),
    meta: {
      resource: 'customer_admin_management',
      action: 'read',
    },
  },
  {
    path: '/support/detail/:id',
    name: 'support_detail',
    component: () => import('@/views/custom_app/support/SupportDetail.vue'),
    meta: {
      resource: 'support_management',
      action: 'read',
    },
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/custom_app/support/SupportList.vue'),
    meta: {
      resource: 'support_management',
      action: 'read',
    },
  },
  {
    path: '/user_management',
    name: 'user_management',
    component: () => import('@/views/custom_app/client/user_management/UserManagement.vue'),
    meta: {
      resource: 'client',
      action: 'read',
    },
  },
  {
    path: '/organization_management',
    name: 'organization-management',
    component: () => import('@/views/custom_app/client/organization_management/OrganizationManagement.vue'),
    meta: {
      resource: 'client',
      action: 'read',
    },
  },
  {
    path: '/organization_management/:id',
    name: 'organization-detail',
    component: () => import('@/views/custom_app/client/organization_management/OrganizationDetail.vue'),
    meta: {
      resource: 'client',
      action: 'read',
    },
  },
  {
    path: '/survey_management/',
    name: 'survey-management',
    component: () => import('@/views/custom_app/client/survery_management/SurveryManagement.vue'),
    meta: {
      resource: 'client',
      action: 'read',
    },
  },
  {
    path: '/qrcode_management/',
    name: 'qrcode_management',
    component: () => import('@/views/custom_app/client/qrcode_management/QRCodeManagement.vue'),
    meta: {
      resource: 'client',
      action: 'read',
    },
  },
  {
    path: '/test/',
    name: 'test',
    component: () => import('@/views/custom_app/components/biolink_editor/BiolinkEditor.vue'),
    meta: {
      resource: 'client',
      action: 'read',
    },
  },
  {
    path: '/feedback_management/',
    name: 'feedback-management',
    component: () => import('@/views/custom_app/client/feedback_management/FeedbackManagement.vue'),
    meta: {
      resource: 'feedback_management',
      action: 'read',
    },
  },
]
