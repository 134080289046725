import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import ability from "@/libs/acl/ability";
import {initialAbility} from "@/libs/acl/config";

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      async config => {
        config.headers['Accept-Language'] = localStorage.getItem('current_locale') || 'en'

        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          const conf = {
            headers: {
              Authorization: `${this.jwtConfig.tokenType} ${accessToken}`,
              'Accept-Language': localStorage.getItem('current_locale') || 'en',
            },
          }
          const res = await axios.get(process.env.VUE_APP_BASE_URL + this.jwtConfig.userinfo, conf)
          if (res.status == 200) {
            const { userData } = res.data
            localStorage.setItem('userData', JSON.stringify(userData))
            ability.update(userData.ability)
          }
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  getCountries(args) {
    return this.axiosIns.get(this.jwtConfig.countries, { params: args })
  }

  loginUser(...args) {
    return this.axiosIns.post(this.jwtConfig.loginUserEndpoint, ...args)
  }

  getNodeUrl(...args) {
    return this.axiosIns.post(this.jwtConfig.getnodeurl, ...args)
  }

  setSurveyAnswer(...args) {
    return this.axiosIns.post(this.jwtConfig.setsurveyanswer, ...args)
  }

  clientregistercreditcartpay(...args) {
    return this.axiosIns.post(this.jwtConfig.clientregistercreditcartpay, ...args)
  }

  paymentcheck(...args) {
    return this.axiosIns.post(this.jwtConfig.paymentcheck, ...args)
  }

  getPlansList(args) {
    return this.axiosIns.get(this.jwtConfig.plan, { params: args })
  }

  getCustomerList(args) {
    return this.axiosIns.get(this.jwtConfig.customer, { params: args })
  }

  getCustomerDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.customer}${id}/`)
  }

  getCustomerUserList(args) {
    return this.axiosIns.get(this.jwtConfig.customeruser, { params: args })
  }

  createCustomerUser(...args) {
    return this.axiosIns.post(this.jwtConfig.customeruser, ...args)
  }

  customerUserDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.customeruser}${id}/`)
  }

  customerUserDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.customeruser}${id}/`)
  }

  updateCustomerUser(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.customeruser}${id}/`, ...args)
  }

  adminPermissionCustomerUser(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.customeruser}${id}/admin_permission/`, ...args)
  }

  getOrganizationTypeList(args) {
    return this.axiosIns.get(this.jwtConfig.getorganizationtype, { params: args })
  }

  createCustomer(...args) {
    return this.axiosIns.post(this.jwtConfig.registerClientEndpoint, ...args)
  }

  loginascompanyuser(...args) {
    return this.axiosIns.post(this.jwtConfig.loginascompanyuser, ...args)
  }

  forgetPasswordSendEmail(...args) {
    return this.axiosIns.post(this.jwtConfig.forgetPasswordSendEmailEndpoint, ...args)
  }

  checkForgetPasswordTokenEndpoint(...args) {
    return this.axiosIns.post(this.jwtConfig.checkForgetPasswordTokenEndpoint, ...args)
  }

  setForgetPasswordEndpoint(...args) {
    return this.axiosIns.post(this.jwtConfig.setForgetPasswordEndpoint, ...args)
  }

  clientregisterfreeplan(...args) {
    return this.axiosIns.post(this.jwtConfig.clientregisterfreeplan, ...args)
  }

  clientregistercheckcountry(args) {
    return this.axiosIns.get(this.jwtConfig.clientregistercheckcountry, { params: args })
  }

  getCompanyOrganizationList(args) {
    return this.axiosIns.get(this.jwtConfig.companyorganization, { params: args })
  }

  getCompanyOrganizationNodesList(args) {
    return this.axiosIns.get(this.jwtConfig.companyorganizationnodes, { params: args })
  }

  createCompanyOrganizationNodes(...args) {
    return this.axiosIns.post(this.jwtConfig.companyorganizationnodes, ...args)
  }

  SubscriptionRenewal(...args) {
    return this.axiosIns.post(this.jwtConfig.subscriptionrenewal, ...args)
  }

  subscriptionUpgradeAmountCalculation(...args) {
    return this.axiosIns.post(this.jwtConfig.subscriptionupgradeamountcalculation, ...args)
  }

  subscriptionUpgrade(...args) {
    return this.axiosIns.post(this.jwtConfig.subscriptionupgrade, ...args)
  }

  companyOrganizationNodesDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.companyorganizationnodes}${id}/`)
  }

  companyOrganizationNodesDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.companyorganizationnodes}${id}/`)
  }

  updateCompanyOrganizationNodes(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.companyorganizationnodes}${id}/`, ...args)
  }

  setupSurveyCompanyOrganizationNodes(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.companyorganizationnodes}${id}/setup_survey/`, ...args)
  }

  setupQRCodeCompanyOrganizationNodes(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.companyorganizationnodes}${id}/setup_qrcode/`, ...args)
  }

  createCompanyOrganization(...args) {
    return this.axiosIns.post(this.jwtConfig.companyorganization, ...args)
  }

  CompanyOrganizationDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.companyorganization}${id}/`)
  }

  CompanyOrganizationDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.companyorganization}${id}/`)
  }

  updateCompanyOrganization(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.companyorganization}${id}/`, ...args)
  }

  getNodeSurveysList(args) {
    return this.axiosIns.get(this.jwtConfig.nodesurveys, { params: args })
  }

  NodeSurveysDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.nodesurveys}${id}/`)
  }

  updateNodeSurveys(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.nodesurveys}${id}/`, ...args)
  }

  getSurveyGroupsList(args) {
    return this.axiosIns.get(this.jwtConfig.surveygroups, { params: args })
  }

  createSurveyGroups(...args) {
    return this.axiosIns.post(this.jwtConfig.surveygroups, ...args)
  }

  SurveyGroupsDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.surveygroups}${id}/`)
  }

  SurveyGroupsDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.surveygroups}${id}/`)
  }

  updateSurveyGroups(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.surveygroups}${id}/`, ...args)
  }

  getCurrencyList(args) {
    return this.axiosIns.get(this.jwtConfig.currency, { params: args })
  }

  createCurrency(...args) {
    return this.axiosIns.post(this.jwtConfig.currency, ...args)
  }

  CurrencyDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.currency}${id}/`)
  }

  CurrencyDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.currency}${id}/`)
  }

  updateCurrency(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.currency}${id}/`, ...args)
  }

  getGroupNodesList(args) {
    return this.axiosIns.get(this.jwtConfig.groupnodes, { params: args })
  }

  createGroupNodes(...args) {
    return this.axiosIns.post(this.jwtConfig.groupnodes, ...args)
  }

  multiAddSurveyGroups(...args) {
    return this.axiosIns.post(`${this.jwtConfig.groupnodes}multi_add/`, ...args)
  }

  nodeMoveToOtherGroup(...args) {
    return this.axiosIns.post(`${this.jwtConfig.groupnodes}move_node_to_other_group/`, ...args)
  }

  convertGroupToOneDesign(...args) {
    return this.axiosIns.post(`${this.jwtConfig.groupnodes}convert_group_to_one_design/`, ...args)
  }

  GroupNodesDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.groupnodes}${id}/`)
  }

  GroupNodesDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.groupnodes}${id}/`)
  }

  updateGroupNodes(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.groupnodes}${id}/`, ...args)
  }

  getPlanList(args) {
    return this.axiosIns.get(this.jwtConfig.plan, { params: args })
  }

  createPlan(...args) {
    return this.axiosIns.post(this.jwtConfig.plan, ...args)
  }

  PlanDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.plan}${id}/`)
  }

  PlanDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.plan}${id}/`)
  }

  updatePlan(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.plan}${id}/`, ...args)
  }

  getSubPlanList(args) {
    return this.axiosIns.get(this.jwtConfig.subplan, { params: args })
  }

  createSubPlan(...args) {
    return this.axiosIns.post(this.jwtConfig.subplan, ...args)
  }

  SubPlanDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.subplan}${id}/`)
  }

  SubPlanDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.subplan}${id}/`)
  }

  updateSubPlan(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.subplan}${id}/`, ...args)
  }

  getPlanLocationList(args) {
    return this.axiosIns.get(this.jwtConfig.planlocation, { params: args })
  }

  getPlanLocationListforClient(args) {
    return this.axiosIns.get(`${this.jwtConfig.planlocation}get_plan/`, { params: args })
  }

  createPlanLocation(...args) {
    return this.axiosIns.post(this.jwtConfig.planlocation, ...args)
  }

  PlanLocationDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.planlocation}${id}/`)
  }

  PlanLocationDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.planlocation}${id}/`)
  }

  PlanLocationSetGlobal(...args) {
    return this.axiosIns.post(`${this.jwtConfig.planlocation}set_global/`, ...args)
  }

  updatePlanLocation(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.planlocation}${id}/`, ...args)
  }

  checkDiscount(...args) {
    return this.axiosIns.post(`${this.jwtConfig.discount}check_discount/`, ...args)
  }

  getDiscountList(args) {
    return this.axiosIns.get(this.jwtConfig.discount, { params: args })
  }

  createDiscount(...args) {
    return this.axiosIns.post(this.jwtConfig.discount, ...args)
  }

  DiscountDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.discount}${id}/`)
  }

  DiscountDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.discount}${id}/`)
  }

  updateDiscount(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.discount}${id}/`, ...args)
  }

  getNodeuseraccessList(args) {
    return this.axiosIns.get(this.jwtConfig.nodeuseraccess, { params: args })
  }

  getUserNodeAccessList(...args) {
    return this.axiosIns.post(`${this.jwtConfig.nodeuseraccess}get_user_node_access_list/`, ...args)
  }

  check_company_user(...args) {
    return this.axiosIns.post(`${this.jwtConfig.nodeuseraccess}check_company_user/`, ...args)
  }

  createNodeuseraccess(...args) {
    return this.axiosIns.post(this.jwtConfig.nodeuseraccess, ...args)
  }

  NodeuseraccessDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.nodeuseraccess}${id}/`)
  }

  NodeuseraccessDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.nodeuseraccess}${id}/`)
  }

  updateNodeuseraccess(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.nodeuseraccess}${id}/`, ...args)
  }

  getFeedbackList(args) {
    return this.axiosIns.get(this.jwtConfig.feedback, { params: args })
  }

  FeedbackDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.feedback}${id}/`)
  }

  updateFeedback(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.feedback}${id}/`, ...args)
  }

  readFeedback(...args) {
    return this.axiosIns.post(`${this.jwtConfig.feedback}read_feedback/`, ...args)
  }

  getFeedbackFullParentNames(...args) {
    return this.axiosIns.post(`${this.jwtConfig.feedback}get_parent_node_names/`, ...args)
  }

  companyreturnurlDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.companyreturnurl}${id}/`)
  }

  updateCompanyreturnurl(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.companyreturnurl}${id}/`, ...args)
  }

  companyprofileDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.companyprofile}${id}/`)
  }

  companyPaymentDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.companysavedcard}${id}/`)
  }

  updateCompanySavedCard(...args) {
    return this.axiosIns.post(`${this.jwtConfig.companysavedcard}update_company_card/`, ...args)
  }

  invoicepaywithcreditcard(...args) {
    return this.axiosIns.post(this.jwtConfig.invoicepaywithcreditcard, ...args)
  }

  updatecompanyprofile(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.companyprofile}${id}/`, ...args)
  }

  getFeedbackCommentsList(args) {
    return this.axiosIns.get(this.jwtConfig.feedbackcomments, { params: args })
  }

  createFeedbackComments(...args) {
    return this.axiosIns.post(this.jwtConfig.feedbackcomments, ...args)
  }

  feedbackCommentsDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.feedbackcomments}${id}/`)
  }

  feedbackCommentsDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.feedbackcomments}${id}/`)
  }

  updateFeedbackComments(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.feedbackcomments}${id}/`, ...args)
  }

  getFeedbackTagList(args) {
    return this.axiosIns.get(this.jwtConfig.feedbacktags, { params: args })
  }

  updatetFeedbackTagList(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.feedbacktags}${id}/`, ...args)
  }

  getCompanyTagsList(args) {
    return this.axiosIns.get(this.jwtConfig.companytags, { params: args })
  }

  getSurveyPreview(...args) {
    return this.axiosIns.post(this.jwtConfig.surveypreview, ...args)
  }

  getnodesurveyanswermediaList(args) {
    return this.axiosIns.get(this.jwtConfig.nodesurveyanswermedia, { params: args })
  }

  createnodesurveyanswermedia(...args) {
    return this.axiosIns.post(this.jwtConfig.nodesurveyanswermedia, ...args)
  }

  nodesurveyanswermediaDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.nodesurveyanswermedia}${id}/`)
  }

  nodesurveyanswermediaDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.nodesurveyanswermedia}${id}/`)
  }

  updatenodesurveyanswermedia(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.nodesurveyanswermedia}${id}/`, ...args)
  }

  getSupportList(args) {
    return this.axiosIns.get(this.jwtConfig.support, { params: args })
  }

  createSupport(...args) {
    return this.axiosIns.post(this.jwtConfig.support, ...args)
  }

  supportDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.support}${id}/`)
  }

  supportDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.support}${id}/`)
  }

  updateSupport(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.support}${id}/`, ...args)
  }

  getSupportCommentMediaList(args) {
    return this.axiosIns.get(this.jwtConfig.supportcommentsmedia, { params: args })
  }

  getSupportCommentsList(args) {
    return this.axiosIns.get(this.jwtConfig.supportcomments, { params: args })
  }

  createSupportComments(...args) {
    return this.axiosIns.post(this.jwtConfig.supportcomments, ...args)
  }

  getSubscriptionList(args) {
    return this.axiosIns.get(this.jwtConfig.subscription, { params: args })
  }

  getInvoiceList(args) {
    return this.axiosIns.get(this.jwtConfig.invoice, { params: args })
  }

  getDashboardInfo(args) {
    return this.axiosIns.get(this.jwtConfig.dashboardinfo, { params: args })
  }

  getNodeReadHistoryList(args) {
    return this.axiosIns.get(this.jwtConfig.nodereadhistory, { params: args })
  }

  getDashboardReportData(...args) {
    return this.axiosIns.post(this.jwtConfig.dashboardreportdata, ...args)
  }

  getNodeQRCodeList(args) {
    return this.axiosIns.get(this.jwtConfig.nodeqrcode, { params: args })
  }

  createNodeQRCode(...args) {
    return this.axiosIns.post(this.jwtConfig.nodeqrcode, ...args)
  }

  getSubNodeQrcodeData(...args) {
    return this.axiosIns.post(`${this.jwtConfig.nodeqrcode}get_sub_node_qrcode_data/`, ...args)
  }

  NodeQRCodeDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.nodeqrcode}${id}/`)
  }

  NodeQRCodeDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.nodeqrcode}${id}/`)
  }

  updateNodeQRCode(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.nodeqrcode}${id}/`, ...args)
  }

  getQRCodeGroupsList(args) {
    return this.axiosIns.get(this.jwtConfig.qrcodegroups, { params: args })
  }

  createQRCodeGroups(...args) {
    return this.axiosIns.post(this.jwtConfig.qrcodegroups, ...args)
  }

  QRCodeGroupsDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.qrcodegroups}${id}/`)
  }

  QRCodeGroupsDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.qrcodegroups}${id}/`)
  }

  updateQRCodeGroups(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.qrcodegroups}${id}/`, ...args)
  }

  getQRCodeGroupNodesList(args) {
    return this.axiosIns.get(this.jwtConfig.qrcodegroupnodes, { params: args })
  }

  createQRCodeGroupNodes(...args) {
    return this.axiosIns.post(this.jwtConfig.qrcodegroupnodes, ...args)
  }

  QRCodeGroupNodesmultiAddSurveyGroups(...args) {
    return this.axiosIns.post(`${this.jwtConfig.qrcodegroupnodes}multi_add/`, ...args)
  }

  QRCodeGroupNodesnodeMoveToOtherGroup(...args) {
    return this.axiosIns.post(`${this.jwtConfig.qrcodegroupnodes}move_node_to_other_group/`, ...args)
  }

  QRCodeGroupNodesconvertGroupToOneDesign(...args) {
    return this.axiosIns.post(`${this.jwtConfig.qrcodegroupnodes}convert_group_to_one_design/`, ...args)
  }

  QRCodeGroupNodesDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.qrcodegroupnodes}${id}/`)
  }

  QRCodeGroupNodesDelete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.qrcodegroupnodes}${id}/`)
  }

  updateQRCodeGroupNodes(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.qrcodegroupnodes}${id}/`, ...args)
  }
}
