export default [
  // {
  //   path: '/dashboard/admin',
  //   name: 'dashboard-admin',
  //   component: () => import('@/views/custom_app/admin/dashboard/Dashboard.vue'),
  //   meta: {
  //     resource: 'admin',
  //     action: 'read',
  //   },
  // },
  {
    path: '/customers',
    name: 'customers-list',
    component: () => import('@/views/custom_app/admin/customer/CustomerList.vue'),
    meta: {
      resource: 'admin',

    },
  },
  {
    path: '/customers/:id',
    name: 'customers-management',
    component: () => import('@/views/custom_app/admin/customer/CustomerManagement.vue'),
    meta: {
      resource: 'admin',

    },
  },
  {
    path: '/general_definitions/currency',
    name: 'currency',
    component: () => import('@/views/custom_app/admin/general_definitions/Currency.vue'),
    meta: {
      resource: 'admin',

    },
  },
  {
    path: '/plan/',
    name: 'plan',
    component: () => import('@/views/custom_app/admin/plan/Plan.vue'),
    meta: {
      resource: 'admin',

    },
  },
  {
    path: '/planlocation/',
    name: 'plan_location',
    component: () => import('@/views/custom_app/admin/plan/PlanLocation.vue'),
    meta: {
      resource: 'admin',

    },
  },
  {
    path: '/discount/',
    name: 'discount',
    component: () => import('@/views/custom_app/admin/discount/Discount.vue'),
    meta: {
      resource: 'admin',

    },
  },
  {
    path: '/plan/:id',
    name: 'subplan_list',
    component: () => import('@/views/custom_app/admin/plan/SubPlan.vue'),
    meta: {
      resource: 'admin',

    },
  },
]
